.onLive-main-container {
  .active-bg {
    background-color: #c9d6f2;
    border-radius: 10px;
  }
  .active-text {
    color: #5e51a1;
  }

  .border-secondary {
    border: 1px solid #d0d5dd !important;
  }
  .rounded-btn {
    border-radius: 1.375rem !important;
  }
  .dropdown-container {
    width: 300px;
  }
  .dropdown-itemMenu {
    padding: 0.5rem;
    border-radius: 6px;
    border-color: rgb(223, 222, 222);
    width: 100%;
    &:focus {
      outline: 0;
    }
    &::placeholder {
      font-size: 10px !important;
      font-weight: 550;
      color: #000;
    }
  }
  .search-container {
    position: relative;
    width: 350px;
    
    img {
      position: absolute;
      z-index: 1 !important;
      top: 10px;
      left: 20px;
    }

    .form-control {
      border-color: #5e51a1; /* Change border color on focus */
      box-shadow: 0 0 5px #5e51a1; /* Add a shadow */
      outline: none; /* Remove the default outline */
    }
  }
  .pagination-container {
    border-top: 1px solid grey;
  }
}
@media screen and (max-width: 1000px) {
  .onlive-filter-container {
    flex-direction: column;
    gap: 12px;
    .search-container {
      width: 103%;
      margin: 0;
    }
    .dropdown-container {
      width: 100%;
      margin-top: 0.4rem;
    }
  }
  // .card-container {
  //   flex-direction: column;
  // }
  .onlive-tab-container {
    margin-top: 0px !important;
    overflow-x: auto;
    justify-content: flex-start !important;
  }
  .onlive-tab-container::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .onlive-tab-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.max-w-onlive{
  max-width: 1000px;
}

.left-search-padding{
  padding-left: 40px;
}

.search-container {
  position: relative;
  width: 350px;
  
  img {
    position: absolute;
    z-index: 1 !important;
    top: 10px;
    left: 20px;
  }

  .form-control {
    border-color: #5e51a1; /* Change border color on focus */
    box-shadow: 0 0 5px #5e51a1; /* Add a shadow */
    outline: none; /* Remove the default outline */
  }
}