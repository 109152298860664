.search-modal-container {
  .card-container {
    width: 500px;
    height: 350px;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    overflow-y: scroll;
    .card {
      width: 100%;
    }
  }
}
@media screen and (max-width: 600px) {
  .search-modal-container {
    .card-container {
      width: 400px !important;
    }
  }
}
@media screen and (max-width: 450px) {
  .search-modal-container {
    .card-container {
      width: 350px !important;
    }
  }
}
@media screen and (max-width: 360px) {
  .search-modal-container {
    .card-container {
      width: 280px !important;
    }
  }
}
