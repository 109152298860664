.banner-main {
  .banner-cards-main {
    display: flex;
    align-items: center;
    margin-top: -35px;
    justify-content: center;
  }

  .slick-dots {
    bottom: 69px;
  }
  .slick-dots li.slick-active button {
    transition: all 0.5s ease 0s;
    width: 25px;
    height: 8px;
    border-radius: 10px;
    background: #5e51a1;
    // box-shadow: rgba(179, 39, 155, 0.54) -1px 2px 5px;
  }

  .slick-dots li button:before {
    display: none;
  }

  .slick-dots li button {
    width: 8px;
    margin-top: 20px;
    height: 8px;
    border-radius: 100%;
    background: rgb(184, 193, 218);
  }
}
@media screen and (max-width: 1000px) {
  .banner-main {
    margin-top: 0px;
    .slick-dots {
      bottom: 10px !important;
    }
  }
  .banner-cards-main {
    margin-top: 0px !important;
    overflow-x: auto;
    justify-content: flex-start !important;
  }
  .banner-cards-main::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .banner-cards-main {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.img-fluid{
  width: 100%;
  height: auto;
}

.banner-image-main {
  max-height: 500px !important;
  overflow: hidden !important;
}

@media screen and (min-width: 1500px) {
  .banner-image-main {
    max-height: 100% !important;
    overflow: hidden !important;
  }
}

@media screen and (max-width: 500px) {
  .banner-cards-main {
    display: flex;
    flex-wrap: wrap;
  }
}