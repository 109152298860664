.navbar {
  .search-main-container {
    width: 100%;
    position: relative;

    

    .search-modal {
      top: 52px;
      left: 8px;
    }
    .search-icon {
      position: absolute;
      top: 7px;
      left: 13px;
    }
    .form-control {
      border-color: #5e51a1; /* Change border color on focus */
      box-shadow: 0 0 5px #5e51a1; /* Add a shadow */
      outline: none; /* Remove the default outline */
    }

  }
  .nav-link {
    font-weight: 550;
    color: #000;
  }
  .dropdown-container {
    position: relative;
    // margin: 0 0.4rem;
    width: 100px !important;

    .dropdown-menu-icon {
      position: absolute;
      top: 10px;
      left: 6px;
    }
    .dropdown-itemMenu {
      padding: 0.4rem 0rem 0.4rem 1.4rem;
      border-radius: 6px;
      border: 0.1rem solid #d0d5dd;
      &:focus {
        outline: 0;
      }
      &::placeholder {
        font-size: 10px !important;
        font-weight: 550;
        color: #000;
      }
    }
    .dropdown-items {
      font-size: 13px !important;
      font-weight: 550;
      color: #000;
    }
  }

  .btn {
    width: 75px !important;
  }
  .btn-Sign-up {
    width: 70px;
    font-size: 14px !important;
    font-weight: 550;
    color: #fff;
  }

  .navbar-toggler {
    border: none;

  }
}
.toggle-button-container {
  padding: 0 !important;
  background: transparent !important;
  border: none !important;
  height: 42px !important;
  width: 50px !important;
  border-radius: 50% !important;
}
.dropdown-menu-container {
  // border: 0;
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.2) !important;
  .email-text{

    display: -webkit-box !important;
      width: 100% !important;
      text-overflow: ellipsis !important;
      height: 34px !important;
      overflow: hidden !important;
      -webkit-line-clamp: 2 !important;
      -webkit-box-orient: vertical !important;
  }
}

// media queries

@media screen and (max-width: 993px) {
  .navbar {
    .w-50 {
      width: 83% !important;
    }
    .mx-4 {
      margin: 0rem !important;
    }
  }
  .login-out-container-larger-view {
    display: none;
  }
}
@media screen and (min-width: 993px) {
  .login-out-container-smaller-view {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .navbar-login{
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 12px;
  }
  .search-width{
    width: 100% !important;
    margin-right: 20px;
  }
  .width-100{
    width: 100% !important;
  }
  .small-flex{
    display: flex;
  }
}
.min-w-ml{
  min-width: 110px;
}

.navbar-login{
  margin-left: 8px;
  margin-right: 8px;
}

.search-width{
  width: 38% !important;
}


.dropdown-country-container {
  position: relative;
  width: 80px; /* Adjust width for proper spacing */


.dropdown-menu-icon {
  position: absolute;
  top: 50%; /* Center the icon vertically */
  left: 10px; /* Adjust horizontal spacing for the icon */
  transform: translateY(-50%);
  width: 16px; /* Icon size */
  height: 16px;
  pointer-events: none; /* Allow clicks on the dropdown */
}

.dropdown-itemMenu {
  padding: 0.4rem 0.6rem 0.4rem 2.2rem; /* Add padding-left for the icon */
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  font-size: 13px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  outline: none;
  width: 100%; /* Full width dropdown */
  appearance: none; /* Remove default browser styles */
  cursor: pointer;
}

.dropdown-itemMenu:focus {
  border-color: #5b9bd5;
  box-shadow: 0 0 4px rgba(91, 155, 213, 0.5);
}

.dropdown-items {
  font-size: 13px;
  font-weight: 550;
  color: #000;
}

}