.card-main-container {
  .card {
    border-radius: 10px !important;
    
    .max-card-h{
      max-height: 50px;
    }

    .card-text {
      color: #1d2939 !important;
    }
    .card-para {
      display: -webkit-box;
      width: 100%;
      text-overflow: ellipsis;
      height: 34px;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .fs-6 {
      font-size: 0.7rem !important;
    }
    .fw-bold {
      font-weight: 600 !important;
    }
  }
}

.card-img-top {
  border-radius: 15px 15px 0 0 ;
}


.coach-card-width{
  max-width: 250px !important;
}


@media screen and (max-width: "450px") {
  .coach-card-width{
    max-width: 100% !important;
  }
}