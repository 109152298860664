@media screen and (max-width: 1000px) {
    .AllCategory-card-container {
      flex-direction: column;
      .col-3{
        width: 100% !important;
        margin-bottom: 1rem;
      }
    }
  }

  .coach-explore-all-coaches{
    display: flex;
    justify-content: space-between;
  }


  @media screen and (max-width: 500px) {
    .coach-explore-all-coaches{
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
.search-main-container{
  .form-control {
    border-color: #5e51a1; /* Change border color on focus */
    box-shadow: 0 0 5px #5e51a1; /* Add a shadow */
    outline: none; /* Remove the default outline */
  }
}
  