.btn-main-container {
  position: relative;
  .btn-main {
    padding: 0.5rem 1rem 0.5rem 1.3rem;
    text-align: left;
    &:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
      background-color: #5e51a1 !important;
    }
  }
}

.btn-main-container-white {
  position: relative;
  .btn-main {
    padding: 0.5rem 1rem 0.5rem 1.3rem;
    text-align: left;
  }
}