.forget-password-container {
  .fs-6 {
    font-size: 14px !important;
  }
  .error-class {
    color: #f04438 !important;
  }
}
@media screen and (max-width: 1000px) {
  .forget-password-container {
    width: 100% !important;
    padding: 0 1rem !important;
  }
}
