
.main-tr-container{
  .main-banner{
      max-height:80% !important;
      // overflow: hidden;
  }
}

@media screen and (min-width: "850px") {
  .sub-btn-l{
      max-width: 200px;
  }
  
}

.bg-card-primary{
  background-color:#5e51a1 ;
}

.bg-progress{
background-color: #20BB73;
}
.bg-muted{
  background-color: #f5f3ff;
  padding: 12px;
}

.continue-btn{
  max-width: 100px;
}

.fix-width{
  width: 180px !important;
}

.mr-arrow-2{
  margin-right: 10px;
}

.tooltip-inner {
  max-width: 500px !important; //define whatever width you want
}
.next-btn-p{
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.w-30{
  max-width: 30% !important;
}

.upload-select{
  display: flex;
  gap: 12px;
}

@media screen and (max-width: "800px") {
  .upload-select{
    display: flex;
    flex-direction: column;
  }  
  .mail-phone-flex{
    display: flex;
    flex-direction: column;
    flex-direction: column;
  }
  .w-30{
    max-width: 30% !important;
  }
  .w-30{
    max-width: 100% !important;
  }
    
}

.mail-phone-flex{
  display: flex;
  justify-content: space-between;
  gap: 12px;
}


@media screen and (max-width: "500px") {
  .word-wrap {
    word-wrap: break-word; /* Break long words */
    word-break: break-word; /* Ensure compatibility with older browsers */
  }
}