.OnliveClassListing-main-container {
  padding: 2.5rem 2.5rem 4rem 2.5rem;

  .OnliveClassListing-main-text {
    color: #5e51a1;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
  }
}
@media screen and (max-width: 430px) {
  .OnliveClassListing-main-container {
    padding: 2.5rem 0rem 4.5rem 0.5rem;
  }
}
