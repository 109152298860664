.resetPassword-container{
  .errorColor {
    color: #f04438 !important;
  }
}


@media screen and (max-width: 1000px) {
  .resetPassword-container {
    width: 100% !important;
    padding: 0 1rem !important;
  }
}
