.pass-container{
    display: flex;

}

.main-pass-container{
    margin-left: 20px;
    margin-right: 20px;
}
.gift-div{
    margin-left: 20px;
    margin-right: 20px;
}


@media screen and (max-width: "450px") {
    .pass-container{
        display: flex;
        flex-direction: column;
    }
}


@media screen and (min-width: "850px") {
    .password-container{
        min-width: 600px;
        background: white;
        padding: 20px;
    }
    .pass-btn{
        max-width: 100px;
    }
    
    
}
