.subscription-container {
  .card {
    margin-top: -10rem !important;
  }
}

.sub-container{
  display: flex;
}
.sub-pill-border{
  background: #F2F2F8;
}

@media screen and (max-width: 768px) {
  .subscription-container {
    .card {
      width: 90% !important;
      margin-top: -4rem !important;
    }
  }
  .sub-container{
    display: flex;
    flex-direction: column;
  }
  .ml-sub{
    margin-left: 0px !important;
  }
}

.sub-m-y{
  margin-top: 30px;
  margin-bottom: 30px;
}

.text-size{
  font-size: 18px;
}
.mt-sub-2{
  margin-top: 8px;
}
.ml-sub{
  margin-left: 30px;
}
