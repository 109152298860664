.feature-card-main {
  width: 100%;

  .card {
    margin: 10px;
    border: none;
    padding: 0.5rem 4rem 0.5rem 0.5rem;
    border-radius: 12px !important;
    cursor: pointer;

    .card-body {
      display: flex;
      align-items: center;
      padding: 0;

      .card-left-body {
        margin: 7px;
      }

      .card-right-body {
        margin: 7px;

        .card-title {
          font-size: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .card-text {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }

  .vertical-card {
    padding: 3rem 0rem;
    border: 1px solid #eaecf0;
    border-radius: 12px;

    .vertical-card-body {
      display: flex;
      flex-direction: column;
      text-align: center;
      .vertical-card-image-container {
        height: 120px;
        width: 80%;
        .vertical-card-image {
          height: 100%;
          object-fit: contain;
          width: 100%;
        }
      }

      .vertical-card-title {
        margin-top: 1.5rem;
        font-weight: 600;
      }

      .vertical-card-text {
        color: #667085;
        margin: 0;
      }
    }
  }

  .verticalWithHover-card {
    padding: 3rem 1rem;
    border-radius: 12px;
    text-align: center;
    background-color: #fff;
    border: 1px solid rgb(223, 222, 222);
    cursor: pointer;
  }

  .verticalWithHover-Active-card {
    padding: 3rem 1rem;
    cursor: pointer;
    border-radius: 12px;
    text-align: center;
    background-color: #f2f2f8;
    border: 1px solid #5e51a1;
  }
}


// @media screen and (max-width: 1000px) {
//   .vertical-card {
//     width: 100%;
//   }
// }

// bootstrap classes overridding css
.bg-grey {
  background-color: #f9fafb;
}

.hover-border {
  &:hover {
    border: 1px solid #5e51a1;
  }
}

.feature-card-main{
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 500px) {
  .feature-card-main{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .card {
    
    padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
  
  }
  .font-center{
    text-align: center;
  }
  .feature-card-width{
    max-width: 150px !important;
  }

}