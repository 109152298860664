.otpPage-main-container {
  width: 25%;
  margin: 2rem auto;
  .error-class {
    border: 1px solid #f04438 !important;
    color: #f04438 !important;
  }
  .input-field {
    border: 1px solid rgb(223, 222, 222);
    font-weight: 600;
    font-size: 30px;
    background-color: transparent;
    border-radius: 4px;
    display: flex;
    text-align: center;
    align-items: flex-end;
    /* padding-left: 10px; */
    width: 100%;
    outline: none;
    line-height: 2;
    color: #5e51a1;
  }
  .input-field:hover {
    outline: 3px solid #9795c6;
  }
  .input-field::placeholder {
    color: rgb(223, 222, 222);
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
@media screen and (max-width: 1000px) {
  .otpPage-main-container {
    width: 100%;
    padding: 0rem 1rem;
  }
}
