
@media screen and (min-width: "990px") {
    .main-tr-container{
        // margin-top: 95px;
        margin-left: 50px;
        width: 100% !important;
    }
    .Sucess-btn{
        
        border-radius: 15px;
        max-height: 26px !important;
        margin-top: 10px;
    }
    .large-end{
        justify-content: end;
    }
    
    .wht-btn{
        max-height: 40px;
        width: 100px;
        background-color: #614EA6 !important;
        
    }
    .tran-container{
        display: flex;
       
    }
    .pt-2{
        margin-right: 25px;
        margin-left:25px
    }
    .pt-1{
        padding-top: 20px !important;
    }
    .mt-2-th{
        margin-top: 12px
    }

    .large-display-none-tra{
        display: none;
    }
}
@media screen and (max-width: "991px") {
    .tran-container{
        display: flex;
        flex-direction: column;
    }
    .Sucess-btn{
        
        border-radius: 15px;
        max-height: 28px !important;
        max-width: 74px;
        margin-top: 10px;
        padding-bottom: 10px;
    }
    .content-between{
        justify-content: space-between;
    }
    .pt-2{
        padding-top: 5px;
    }
    .main-title{
        margin-left: 50px;
    }
    .wht-btn{
        
        width: 100px;
        background-color: #614EA6 !important;
        
    }

    .small-display-none-tra{
        display: none;
    }

    .date-width{
        min-width: 200px;
    }
}


@media screen and (min-width: 450px) and (max-width: 991px) {
    
}
  