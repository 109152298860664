.main-container{
    margin-left: 25px;
    margin-top: 30px;
    
}

.my-account{
    background-color: #eae4f5;
    color: #532d9b;
}

.active-line{
    background-color: #eae4f5;
    border-radius: 0% !important;
    border-left:  2px solid #532d9b;
}

.min-width{
    min-width: 260px;

}
.min-width-0{
    min-width: 140px;

}
.min-w{
    min-width: 95px;

}

.padding-left{
    padding-left: 20px !important;
}

@media screen and (max-width: "450px") {
    .min-width{
        min-width: 100%;
    
    }
    .active-line{
        // background-color: white;
        border-radius: 0% !important;
        border-left:  none;
    }
  }
  