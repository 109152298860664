.onLiveDetailPage-main-container {
  .left-inner-container {
    width: 60%;
  }
  .right-inner-container {
    width: 40%;
    background-color: #f9fafb;
    padding: 2rem;
    .tag-container {
      background-color: #f2f2f8;
      padding: 0.2rem 0.4rem;
      border-radius: 5px;
      margin-top: 0.5rem;
    }
  }
  .hover-effect {
    &:hover {
      background-color: #f6fef9 !important;
      border: 1px solid #12b76a !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .right-inner-container {
    padding: 0.2rem !important;
  }
}

.skeleton-loader {
  width: 100%;
  max-width: 400px;
  height: 55px;
}
.skeleton-height-banner{
  max-height: 550px;
}

@media (max-width: 768px) {
  .skeleton-loader {
    max-width: 300px; /* Adjust as necessary for smaller screens */
  }
  .skeleton-height-banner{
    max-height: 200px;
  }
}

@media (max-width: 480px) {
  .skeleton-loader {
    max-width: 300px; /* Adjust as necessary for very small screens */
  }
}