@media screen and (max-width: "786px"){
    .below-container{
        width:100% !important;
        // img{
        //     width:100% !important;
        // }
    } 
    .subscriber-container{
        flex-direction: column;
    }
    .subscriber-cancel{
        max-width: 100px !important;
    }
    .full-width{
        max-width: 200px !important;
    }

}

.bg-card-slati{
    background-color: #F5F5F5;
}

@media screen and (max-width: "766px"){
    .mb-3-my{
        margin-bottom: 16px;
    }

    .subscription-container {
        .subscriber-container {
          margin-top: 12px !important;
        }
      }
    
      .subscriber-container-check{
        display: flex;
        justify-content: center;
        
    }
}

.thick-border{
    border-width: 3px !important;
}

.full-width{
    width: 270px !important;
}

.subscriber-container{
    display: flex;
    justify-content: center;
}


.subscriber-card {
    transition: all 0.3s ease; /* Smooth transition for all properties */
    border: 3px solid transparent
}

.subscriber-card:hover {
    transform: scale(1.05); /* Scale the element on hover */
    z-index: 1;
    border-color: #5e51a1; /* Add a purple border on hover */
}



.subscription-container {
    .subscriber-container {
      margin-top: -6rem !important;
    }
  }

  .subscriber-container-check{
    display: flex;
    justify-content: center;
    
}

.glow-border{
    /* Change border color on focus */
    box-shadow: 0 0 5px #6a55d3 !important; /* Add a shadow */
    outline: none; /* Remove the default outline */
  }

  .glow-border-white{
    /* Change border color on focus */
    box-shadow: 0 0 5px #5e51a1 !important; /* Add a shadow */
    outline: none; /* Remove the default outline */
  }

  .full-width-sub{
    width: 35% !important;
  }


