.img-width-sub{
    min-width: 550px;
}
.bg-sub-sucess{
    background-color: #12B67A;
}

.ser-width{
  width: 400px !important;
}

.share-btn-flex{
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 700px) {
  .url-text {
    font-weight: 600;
    /* Tailwind's 'text-primary' equivalent */
    font-size: 0.875rem; /* text-xs */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin-bottom: 10px;
}
    .ser-width{
      width: 300px !important;
    }
    .img-width-sub{
        width: 80% !important;
    }
    .certificat-container-main{
        display: flex;
        flex-direction: column-reverse;
    }
    .img-profile{
       
        margin-right: 50px;
    }
    .share-btn-flex{
      display: flex;
      flex-direction: column;
      max-width: 100% !important;
    
    }
    .max-w-flex{
      max-width: 150px;
    }
    .flex-btn-width{
      max-width: 100px;
    }
   
  }

.max-width-certificate{
    max-width: 425px;
}
.certificat-container-main{
    display: flex;
    align-items: start;
}
.img-profile{
    height: 70px;
    width: 70px;
    margin-bottom: 95px;
}

.search-cert-container {
    width: 100%;
    position: relative;
    .search-modal {
      top: 52px;
      left: 8px;
    }
    .search-icon {
      position: absolute;
      top: 7px;
      left: 13px;
    }
    .form-control {
      padding: 0.4rem 0.4rem 0.4rem 2rem;
      &:hover{
        border: 0 !important;
        outline:none !important
      }
    }
  }
  


  