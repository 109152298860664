

@media screen and (max-width:"1000px"){
    .onLiveDetailPage-inner-container{
        flex-direction:column !important;
        .left-inner-container{
            width:100% !important;
        }
        .right-inner-container{
            width:100% !important;
        }
    }
}

