.search-container {
  .card-main-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.my-full-height{
  min-height: 50vh;
}