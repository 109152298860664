.paginationComponent {
  display: flex;
  align-items: center;
  list-style: none;
  padding-left: 0;
  gap: 30px;
  justify-content: center;
  border-top: 1px solid #eaecf0;
  padding-top: 1.3rem;
}

.pagination-centered {
  justify-content: center;
}

.previous,
.next {
  padding-inline: 13px;
  padding-block: 7px;
  cursor: pointer;
  transition: all 0.3s ease-in;
  display: flex;
  gap: 0.5rem;
}

.previous {
  margin-right: auto;
  margin-top: 8px;
}

.next {
  margin-left: auto;
  margin-top: 8px;
}

.previous a,
.next a {
  color: #475467;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  transition: all 0.3s ease-in;
  margin-bottom: 7px;
}

.previous:hover,
.next:hover {
  background-color: #f9fafb;
}

.previous::before {
  content: url("../../../images/Paginate/prevArrow.svg");
}

.previous.hide-arrow::before,
.next.hide-next-arrow::before {
  content: none;
}

.next::after {
  content: url("../../../images/Paginate/nextArrow.svg");
}

.selected {
  background-color: #eaecf0;
  padding: 0.4rem 1rem;
  border-radius: 50%;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .paginationComponent {
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 1rem;
  }

  .previous,
  .next {
    padding-inline: 10px;
    padding-block: 5px;
    gap: 0.3rem;
    
  }

  .previous a,
  .next a {
    font-size: 12px;
    
  }

  .selected {
    padding: 0.3rem 0.8rem;
  }
}

@media (max-width: 480px) {
  .paginationComponent {
    gap: 5px;
    padding-top: 0.8rem;
  }

  .previous,
  .next {
    padding-inline: 8px;
    padding-block: 4px;
    gap: 0.2rem;
  }

  .previous a,
  .next a {
    font-size: 10px;
    margin-top: 3px;
  }

  .selected {
    padding: 0.2rem 0.6rem;
  }
  .content-center-small{
    justify-content: center;
  }
}

@media (min-width: 768px) {
 .mx-5-w{
  margin-left: 20px;
  margin-right: 20px;
 }

}