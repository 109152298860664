.horizental-card-container {
  .card {
    width: 1150px;
    margin: 1rem !important;
    .card-img {
      height: 300px;
      object-fit: fill !important;
    }
    .card-text {
      display: -webkit-box;
      width: 100%;
      text-overflow: ellipsis;
      height: 50px;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

@media screen and (max-width: "1207px") {
  .horizental-card-container {
    .card {
      width: 400px;
      height: 550px;
      margin: 1rem !important;
      .card-details-container {
        flex-wrap: wrap;
        gap: 0.3rem;
      }
    }
    .card-title {
      display: -webkit-box !important;
      width: 100% !important;
      text-overflow: ellipsis !important;
      height: 20px !important;
      overflow: hidden !important;
      -webkit-line-clamp: 1 !important;
      -webkit-box-orient: vertical !important;
    }
    .card-text {
      display: -webkit-box !important;
      width: 100% !important;
      text-overflow: ellipsis !important;
      height: 20px !important;
      overflow: hidden !important;
      -webkit-line-clamp: 1 !important;
      -webkit-box-orient: vertical !important;
    }
  }
}

@media screen and (max-width: "430px") {
  .horizental-card-container {
    .card {
      width: 340px !important;
      height: 450px;
      margin: 0.2rem !important;
      .card-img {
        width: 100%;
        height: 189px !important;
        object-fit: cover;
      }
    }
  }
  .mx-2-small{
    margin-left: 8px;
  }
  .card-high{
    min-height: 480px !important;
  }
  .live-btn{
    padding-bottom: 5px;
  }
  
}

.live-btn{
  max-width: 110px;
  border-radius: 20px !important;
  
}
