.category-card-container {
  .card-image-container {
    height: 40px;
    width: 30%;
    .card-image {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }
}
