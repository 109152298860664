.signIn-container {
  .fs-6 {
    font-size: 14px !important;
  }
  .errorColor {
    color: #f04438 !important;
  }
}
@media screen and (max-width: 1000px) {
  .signIn-right-container {
    display: none;
  }
  .signIn-container {
    width: 100%;
    margin: 0 !important;
    padding: 0.1rem;
  }
}
