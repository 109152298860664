// .certificate-main{
//     background: linear-gradient(to bottom, #5e51a1 50%, white 50%);
// }

.half-background {
    background: linear-gradient(to bottom, #5e51a1 50%, white 30%);
    min-height: 100vh; /* Full viewport height */
    padding-top: 2rem;
  }
  
  .header-content {
    width: 100%;
    max-width: 1200px;
    margin-bottom: 2rem;
  }
  
  .certificate-container {
    width: 100%;
    max-width: 900px;
  }
  
  .text-right {
    text-align: right;
  }

  .text-left {
    text-align: left !important;
  }
  
  .bg-primary {
    background-color: #6c63ff !important; /* Adjust this color to match the exact shade in the image */
  }
  
  .text-center p, .text-center h2, .text-center h4 {
    margin-bottom: 0.5rem;
  }

  .img-fluid{
    width: 100%;
    height: auto;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
}

.button {
    margin-right: 0.5rem;
}

.button:last-child {
    margin-right: 0;
}

@media (max-width: 768px) {
    .button-container {
        flex-direction: column;
    }

    .button {
        margin-right: 0;
        margin-bottom: 0.5rem;
    }

    .button:last-child {
        margin-bottom: 10px;
    }

    .half-background {
        background: linear-gradient(to bottom, #5e51a1 70%, white 30%);
        min-height: 30vh; /* Full viewport height */
        padding-top: 2rem;
      }
}
