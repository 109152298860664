.input-container {
  .form-control {
    border: 1px solid #d0d5dd !important;
  }
  .input-password-group {
    position: relative;
    .input-password-icon-container {
      position: absolute;
      top: 6px;
      right: 13px;
      img {
        cursor: pointer;
      }
    }
  }
  .error-border {
    border: 1px solid #f04438 !important;
  }
}
input::placeholder {
  font-size: 14px;
  color: #667085 !important;
}
