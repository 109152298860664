

.upcoming-cls-banner{
  max-width: 60% !important;
}

.bannerClass-main-container {
  // background-color: #f9fafb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 0;
  gap: 1rem;
  
  .border-radius {
    border-radius: 5rem 0 0 0;
  }
  .bannerWithText-btn-container {
    width: 40%;
  }
  .banner-class-container {
    width: 100%;
    overflow: hidden !important;

  }
  .bannerWithText-left-container {
    width: 50%;
  }
}

@media screen and (max-width: 1000px) {
  .bannerWithText-main-container {
    flex-direction: column;
    .bannerWithText-left-container {
      width: 100% !important;
      margin-top: 3rem;
    }
    .banner-class-container {
      width: 100% !important;
      overflow: hidden !important;
      order: -1;
      img {
        width: 100% !important;
      }
    }
  }
  .upcoming-cls-banner{
    max-width: 100% !important;
}
}


.todays-classes{
  max-height: 250px !important;
  max-width: 400px !important;
}

.today-centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* or any specific height you need */
}




.class-height{
// max-height: 250px !important;
// overflow: hidden;
 margin-bottom: 0 !important;
}


@media screen and (max-width: 500px) {
.margin-l{
  margin-left: 18px !important;
}

}


@media screen and (min-width: 501px) {
.magin-x-5{
  margin-left: 30px !important;
  margin-right: 30px !important;
}
}