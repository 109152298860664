.verticalCard-main-container {
  // width: 100%;
  .card-title {
    display: -webkit-box;
    width: 100%;
    text-overflow: ellipsis;
    // height: 50px;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .card-text {
    color: #475467;
    font-size: 15px;
  }
  .card {
    width: 350px;
    .card-img-top {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }
}
.min-height-onstage{
  min-height: 430px;
}

.min-height-card{
  min-height: 405px
}

@media screen and (max-width: "550px") {
  .verticalCard-main-container {
    .card {
      width: 325px;
      margin: 0.2rem !important;
      
    }
  }
  .card-img-top {
    width: 100%;
    height: 200px;
    object-fit: fill !important;
  }
}

.truncate-2-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bg-purple{
  background-color: #eae4f5;
}

.text-primary {
  color: #5e51a1 !important;
}

.rounded-radius{
  background-color: #5e51a1;
  border-radius: 15px;
}