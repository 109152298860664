.InterestSelection-main-container {
  background-color: #f9fafb;
  height: 100vh;

  .InterestSelection-card-container {
    width: 100% !important;
  }

  .InterestSelection-btn-container {
    margin-left: auto;
    width: 100px;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 1000px) {
  .InterestSelection-btn-container {
    width: 100% !important;
  }
  .InterestSelection-card-container {
    display: grid !important;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    width: 100%;
  }
}
