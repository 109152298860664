.liveCourse-main-container {
  background-color: #f5f3ff;
  padding: 2.5rem 0rem 4rem 1.5rem;
}
@media screen and (max-width: "1207px") {
  .liveCourse-main-container {
    padding: 3rem 0rem 3rem 0.2rem;
  }
}
@media screen and (max-width: "450px") {
  .liveCourse-main-container {
    padding: 3rem 0rem 4.5rem 0.5rem;
  }
  .liveCourse-main-title {
    margin-bottom: 1rem !important;
    margin-left: 0 !important;
  }
  .title-flex{
    display: flex;
    flex-direction: column;
  }
}

.title-flex{
  display: flex;
  justify-content: space-between;
}

.title-new-flex-onlive{
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: "770px") {

  .title-new-flex-onlive{
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .live-course-button{
    max-width: 100px;
    margin-left: 7px;
  }
}
