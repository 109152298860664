.coachCard-main-container {
    // width: 100%;
    .card {
      width: 350px;
      min-height: 220px;
      .card-img-top {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
    }
  }
  
  @media screen and (max-width: "450px") {
    .coachCard-main-container {
      .card {
        width: 300px;
        margin: 0.2rem !important;
      }
    }
    .max-my-card{
      max-width: 355px !important;
    }
  }
  
  .circle {
    width: 15px;
    height: 15px;
    background-color: red;
    border-radius: 50%;
  }

 
  .margin-r{
    margin-right:8px ;
  }


  .tooltip-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 150px; /* Adjust as needed */
  }
  
  .tooltip-text:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    background: black;
    color: white;
    padding: 5px;
    border-radius: 4px;
    white-space: nowrap;
    transform: translateY(-100%);
  }
  