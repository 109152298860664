.btn-primary {
  background-color: #5e51a1 !important;
  border: none !important;
}

.btn-primary:focus {
  outline: none !important;
}

// .bg-primary {
//   background-color: #f5f3ff !important;
// }

.btn-warning {
  background-color: #f79009 !important;
}

.btn-outline-secondary {
  border: 0.1rem solid #d0d5dd;
}

.text-primary {
  color: #5e51a1 !important;
}
.text-success {
  color: #32d583;
}
.fs-6 {
  font-size: 14px !important;
}
.fs-7 {
  font-size: 13px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-24 {
  font-size: 24px !important;
}

.fs-30 {
  font-size: 30px !important;
}
.border-secondary {
  border-color: rgb(223, 222, 222) !important;
}
.cursor-pointer {
  cursor: pointer !important;
}
.gray-700 {
  color: #344054 !important;
}
.btn:disabled {
  background-color: #b0afd4 !important;
}

//Removing Hover effect from buttons

.btn {
  &:hover {
    background-color: var(--bs-btn-bg);
    color: var(--bs-btn-color);
  }
  &:focus {
    background-color: var(--bs-btn-bg);
    color: var(--bs-btn-color);
  }
  &.active {
    &:hover {
      background-color: var(--bs-btn-active-bg);
      color: var(--bs-btn-active-color);
    }
    &:focus {
      background-color: var(--bs-btn-active-bg);
      color: var(--bs-btn-active-color);
    }
  }
}

// input[type="radio"] {
//   accent-color: #f5f3ff !important;
// }
.radio-btn-primary {
    accent-color: #5e51a1 !important;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.aspect-ratio-1x1 {
    aspect-ratio: 1 !important;
}

.my-learning-tab {
    .tab-content {
        width: 100% !important;
    }
}

.search-container {
    display: flex !important;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    // border: var(--bs-border-width) solid var(--bs-border-color) !important;

    img {
        margin-right: 0.5rem !important;
    }

    input {
        border: none !important;
    }
}

.verifiction-input {
    font-size: 25px !important;
    font-weight: 500 !important;
    border: 1px solid $primary !important;
    width: 2.2em !important;
    height: 2.2em !important;
}

.verifiction-input[value=""] {
    border-color: #C4C4C4 !important;
}

.bg-opacity-20 {
    --bs-bg-opacity: 0.2 !important;
}

.vh-80 {
    height: 80vh !important;
}

.w-xl-25 {
    @media (min-width: 1200px) {
      width: 25% !important;
    }
}

.account-navbar-collapse {
    @media (max-width: 1200px) {
        flex-basis: 100% !important;
        flex-grow: 1 !important;
        align-items: center !important;
    }
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1 !important;
}

