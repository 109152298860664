.FeaturedCategory-main-container {
  padding: 2.5rem;
  .FeaturedCategory-main-text {
    color: #5e51a1;
  }
}

@media screen and (max-width: 1000px) {
  .FeaturedCategory-card-container {
    flex-direction: column;
    .col-4{
      width: 100% !important;
      margin-bottom: 1rem;
    }
  }
}
@media screen and (max-width: 430px) {
  .FeaturedCategory-main-container {
    padding: 0.5rem !important;
  }
}
