.siteslider-main-container {
  .slick-dots {
    z-index: 0;
  }
  .slick-dots li.slick-active button {
    transition: all 0.5s ease 0s;
    width: 25px;
    height: 8px;
    border-radius: 10px;
    background: #5e51a1;
    // box-shadow: rgba(179, 39, 155, 0.54) -1px 2px 5px;
  }

  .slick-dots li button:before {
    display: none;
  }

  .slick-dots li button {
    width: 8px;
    margin-top: 20px;
    height: 8px;
    border-radius: 100%;
    background: rgb(184, 193, 218);
  }
}

@media screen and (max-width: 1000px) {
  .siteslider-main-container {
    .slick-dots {
      text-align: start;
    }
  }
}
@media screen and (max-width: 430px) {
  .siteslider-main-container {
    .nextArrow {
      bottom: -60px !important;
    }
    .prevArrow {
      bottom: -60px !important;
    }
    .slick-dots {
      bottom: -10px !important;
    }
  }
}
