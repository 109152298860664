.bannerWithText-main-container {
  background-color: #f9fafb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 3rem 3rem;
  gap: 1rem;
  .bannerWithText-heading {
    font-size: 40px;
    color: #1d2939;
  }
  .border-radius {
    border-radius: 5rem 0 0 0;
  }
  .bannerWithText-btn-container {
    width: 40%;
  }
  .bannerWithText-right-container {
    width: 100%;
    max-height: 650px !important;
    overflow: hidden !important;

  }

  .max-img-height{
    max-height: 650px
  }
  .bannerWithText-left-container {
    width: 50%;
  }
}

@media screen and (max-width: 1000px) {
  .bannerWithText-main-container {
    flex-direction: column;
    .bannerWithText-left-container {
      width: 100% !important;
      margin-top: 3rem;
    }
    .bannerWithText-right-container {
      max-height: 500px !important;
      overflow: hidden !important;
      order: -1;
      img {
        width: 100% !important;
      }
    }
  }
}

