.coach-review-width{
    max-width:1200px;
    
}
.coach-h{
  min-height: 210px;
}


.image-coach-container {
  width: 100%;
  height: 450px; /* Fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* To ensure the image does not overflow */
}

.image-coach-container img {
  width: 100%;
  height: 450px; /* Fixed height */
  object-fit: cover; /* Ensures the image covers the container without distortion */
}

.coach-bgImg{
    max-height:40%; 
    width: 100%;
}

.coach-profile{
  margin-top: 60px;
  margin-left: 35px;
}

.coach-icon{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: lightblue;
    border: 4px solid white;
    position: absolute;
    top: 250px; /* Position it where the red and white split */
    left: 10%;
    transform: translate(-50%, -50%);
}
.small-icons{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: lightblue;
}

.small-icon-container{
    left: 15%;
    position: absolute;
    
}

.main-icon-container{
    display: flex;
}

@media (max-width: 768px) {
    .coach-icon{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: lightblue;
        border: 4px solid white;
        position: absolute;
        top: 170px; /* Position it where the red and white split */
        left: 20%;
        transform: translate(-50%, -50%);
    }

    .small-icon-container{
      left: 0%;
      top:25% ;
      position: absolute;
      
  }

  .coach-profile{
    margin-top: 50px;
    margin-left: 20px;
  }
  
  .coach-review-width{
    padding: 10px;
}
    
}

@media screen and (max-width: 1000px) {
    .Coach-card-container {
      flex-direction: column;
      .col-6{
        width: 100% !important;
        margin-bottom: 1rem;
      }
    }
  }

  .Coach-main-container {
    background-color: #f5f3ff;
    padding: 2.5rem 0rem 4rem 1.5rem;
  }
  @media screen and (max-width: "1207px") {
    .Coach-main-container {
      padding: 3rem 0rem 3rem 0.2rem;
    }
  }
  @media screen and (max-width: "450px") {
    .Coach-main-container {
      padding: 3rem 0rem 4.5rem 0.5rem;
    }
    .liveCourse-main-title {
      margin-bottom: 1rem !important;
   
    }
    
  }

  
  
  .course-bullets{
    font-size: medium;
  }
  .bullet{
    background-color:#e0d4fa ;
    color: blueviolet;
    padding-left: 7px;
    padding-right: 7px;
    border-radius: 10px;
    
  }
  
  .cource-history{
    // max-width: 500px;
    margin-left: 25px;
    margin-top: 10px;
  }

  .horizental-card-container {
    .card {
      width: 1150px;
      margin: 1rem !important;
      .card-img {
        height: 300px;
        object-fit: cover;
      }
      .card-text {
        display: -webkit-box;
        width: 100%;
        text-overflow: ellipsis;
        height: 50px;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
  
  @media screen and (max-width: "1207px") {
    .horizental-card-container {
      .card {
        width: 400px;
        height: 550px;
        margin: 1rem !important;
        .card-details-container {
          flex-wrap: wrap;
          gap: 0.3rem;
        }
      }
      .card-title {
        display: -webkit-box !important;
        width: 100% !important;
        text-overflow: ellipsis !important;
        height: 20px !important;
        overflow: hidden !important;
        -webkit-line-clamp: 1 !important;
        -webkit-box-orient: vertical !important;
      }
      .card-text {
        display: -webkit-box !important;
        width: 100% !important;
        text-overflow: ellipsis !important;
        height: 20px !important;
        overflow: hidden !important;
        -webkit-line-clamp: 1 !important;
        -webkit-box-orient: vertical !important;
      }
    }
  }
  
  @media screen and (max-width: "430px") {
    
    .horizental-card-container {
      .card {
        width: 320px;
        height: 450px;
        margin: 0.2rem !important;
        .card-img {
          width: 100%;
          height: 189px !important;
          object-fit: cover;
        }
      }
    }
    
  }

  @media screen and (min-width: "800px") {
    .course-alignment{
      margin-left: 80px !important;
    }
  }

  .cource-text{
    color: blueviolet;
    font-size: small;
    margin-bottom: 8px;
  }
  
  .review-margin{
    margin-left: 30px;
  }

  .view-all{
    margin-right: 20px;
  }

  .coach-bgImg {
    width: 100%;
    height: 300px;
    // object-fit: cover;
  }
  
  .card {
    width: 100%; /* Ensures the card itself is responsive */
    max-width: 1200px; /* Optional: to constrain the max width of the card */
    margin: auto;
  }
  .coach-bgImg {
    height: 250px !important; /* Adjust the height for smaller screens */
  }
  
  /* Media Queries for Mobile Responsiveness */
  @media (max-width: 768px) {
    .coach-bgImg {
      height: 200px; /* Adjust the height for smaller screens */
    }
  
    .card {
      margin: 0 10px; /* Add some margin to the card for mobile view */
    }
  
    .main-icon-container {
      display: flex;
      justify-content: center;
    }
  
    .coach-icon {
      width: 120px; /* Adjust the size of the avatar for mobile view */
      height: 120px;
    }
  }  


 

.coach-card-img-new {
  width: 100% !important;
  max-width: 400px !important;
  height: auto !important;
}

@media (max-width: 768px) {
  .coach-card-img-new {
    max-width: 300px !important;
  }
}

@media (max-width: 480px) {
  .coach-card-img-new {
    max-width: 250px !important;
  }
}
