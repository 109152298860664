.signUp-main-container {
  width: 100%;
  .signUp-left-container {
    width: 30%;
    margin: 1rem auto;
    .signUp-inner-container {
      background-color: #f9fafb;
      border-radius: 12px;
    }
  }
  .signUp-right-container {
    width: 50%;
    background-color: #f9fafb;
    padding: 2rem;
    padding-top: 1.5rem;
  }
  .fs-6 {
    font-size: 13px !important;
  }

  .vertical-line {
    background-color: #eaecf0;
    width: 2px;
    height: 50px;
    opacity: 1;
    margin: auto;
  }
  .errorColor {
    color: #f04438;
  }
  //html select custom styles
  select {
    appearance: none;
    /*  safari  */
    -webkit-appearance: none;
    /*  other styles for aesthetics */
    width: 100%;
    font-size: 14px;
    padding: 0.39rem 0.75rem !important;
    background-color: #fff;
    border: 1px solid #d0d5dd !important;
    border-radius: 0.25rem;
    color: #667085 !important;
    cursor: pointer;
  }
}

@media screen and (max-width: 1000px) {
  .signUp-right-container {
    display: none;
  }
  .signUp-left-container {
    width: 100% !important;
    padding: 1rem;
  }
}
