.features-main-container {
  padding: 0 3rem;

  .feature-main-card-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 800px) {
  .feature-main-card-container {
    display: grid !important;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    width: 100%;
  }
}
@media screen and (max-width: 800px) {
  .features-main-container {
    padding: 0 0.5rem !important;
  }
}
