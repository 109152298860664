.online-btn{
    max-height: 25px;
    border-radius: 20px !important;
   
}

@media screen and (max-width: "800px") {
    .online-btn{
        max-height: 25px;
        border-radius: 20px !important;
        max-width: 110px;
    }
  }

.hide-on-medium{
    display: none;
}

@media screen and (max-width: "574px") {
    .hide-on-medium{
        display: block !important;
    }
}
.bg-primary-btn{
    background-color: #5e51a1;
}