.FeaturedBestInstructor-main {
  margin: 4rem 0;
  padding-left: 2.5rem;
  .btn-container {
    width: 170px !important;
  }
  .FeaturedBestInstructor-main-right-container {
    background-image: url(../../../images/FeaturedBestInstructor/bg.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;

    .FeaturedBestInstructor-main-Inner {
      display: flex;
      padding-right: 2.5rem;
    }
  }
}
.img-size{
  width: 100%;
  height:180px !important;
  overflow: hidden;
}

@media screen and (max-width: 1000px) {
  .FeaturedBestInstructor-main {
    display: flex;
    flex-direction: column;
    .FeaturedBestInstructor-main-Inner {
      overflow-x: auto !important;
      justify-content: flex-start !important;
    }
    .FeaturedBestInstructor-main-left-container {
      width: 100% !important;
    }
    .FeaturedBestInstructor-main-right-container {
      width: 100% !important;
      .card-main-container {
        margin: 0rem !important;
        margin-right: 0.5rem !important;
      }
      .container {
        display: flex !important;
        margin-left: 0rem !important;
        .row {
          flex-wrap: nowrap !important;
          .col-4 {
            padding: 0rem !important;
            padding-right: 0.7rem !important;
            width: 100% !important;
          }
        }
      }
    }
  }
  .FeaturedBestInstructor-inner-container {
    flex-direction: column !important;
  }
  .mb-5 {
    margin-bottom: 1rem !important;
  }
}
@media screen and (max-width: 600px) {
  .FeaturedBestInstructor-main-container {
    width: 100%;
    padding-left: 0rem !important;
    background-size: 700px !important;
    background-position: 100px;
    margin-top: 1rem;
  }
  .FeaturedBestInstructor-main-Inner::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .FeaturedBestInstructor-main-Inner {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
@media screen and (max-width: 430px) {
  .FeaturedBestInstructor-main {
    padding-left: 0.5rem;
  }
  .FeaturedBestInstructor-main-Inner {
    display: flex;
    flex-direction: column;
    padding-right: 0.5rem;
  }
  
}

@media screen and (min-width: 1400px) {
  .img-size{
    width: 100%;
    height:250px;
  }
}