.m-t-2{
    margin-top: 5px;
}

.info-div{
    width: 210px;
    max-height: 95px;
}

.user-p-2{
  padding: 16px;
}

.search-main-container {
    width: 100%;
    position: relative;
    .search-modal {
      top: 52px;
      left: 8px;
    }
    .search-icon {
      position: absolute;
      top: 7px;
      left: 13px;
    }
    .form-control {
      padding: 0.4rem 0.4rem 0.4rem 2rem;
      &:hover{
        border: 0 !important;
        outline:none !important
      }
    }
  }
  .dropdown-container {
    width: 300px;
  }
  .dropdown-itemMenu {
    padding: 0.5rem;
    border-radius: 6px;
    border-color: rgb(223, 222, 222);
    width: 100%;
    &:focus {
      outline: 0;
    }
    &::placeholder {
      font-size: 10px !important;
      font-weight: 550;
      color: #000;
    }
  }

  .max-w-certificate{
    max-width: 200px;
  }

  .m-x-1{
    margin-right: 10px;
  }

  @media screen and (max-width: "1000px") {
    .small-img{
      width: 50%;
      height: 50% !important;
    }
    .drop-search{
      flex-direction: column;
    }
    .search-width{
      width: 100% !important;
      padding-left: 10px;
      padding-right: 10px;
      justify-content: center;
    }
    .max-w-certificate{
      max-width: 96% !important;
      padding-top: 10px;
    }
    .m-x-1{
      margin-left: 5px;
      margin-right:5px ;
    }
    .user-cards{
      flex-direction: column;
    }
    .info-div{
      max-width: 50% !important;
      max-height: 95px;
  }
  .user-p-2{
    padding: 8px;
  }
  }

  @media screen and (max-width: "1000px") {
    .my-full-w{
      width: 100% !important;
    }
  }