.editPreference-main-container{
    .search-main-container {
        width: 100%;
        position: relative;
        .search-modal {
          top: 52px;
          left: 8px;
        }
        .search-icon {
          position: absolute;
          top: 7px;
          left: 13px;
        }
        .form-control {
          padding: 0.4rem 0.4rem 0.4rem 2rem;
          &:hover{
            border: 0 !important;
            outline:none !important
          }
        }
      }
}


.custom-modal .modal-dialog {
  min-width: 800px !important; /* Adjust the width as needed */
  width: 90%;
}

.modal-body-scrollable {
  max-height: 400px; /* Adjust height as needed */
  overflow-y: auto;
  padding: 10px;
}
