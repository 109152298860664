.LearnAnytimeClassListing-main-container {
  padding: 2.5rem 2.5rem 0rem 2.5rem;

  .LearnAnytimeClassListing-main-text {
    color: #5e51a1;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
  }
}
@media screen and (max-width: 430px) {
  .LearnAnytimeClassListing-main-container {
    padding: 2.5rem 0rem 4.5rem 0.5rem;
  }
}

.learn-flex-new{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media screen and (max-width: 430px) {
  .learn-flex-new{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

