.main-reward-container{
    // margin-top: 95px;
    
    max-width: 750px !important;
}

.max-width-reward{
    max-width: 100px;
}
.bg-reward{
    background-color: #F9FAFB;
}

.uploaded-img{
    
    border-radius: 10px;
    border-color: blueviolet;
    
}