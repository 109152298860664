.VerticalCardForLearnAnytime-main-container {
  width: 100%;
  .card-title {
    display: -webkit-box;
    width: 100%;
    text-overflow: ellipsis;
    // height: 50px;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  .card-text {
    color: #475467;
    font-size: 15px;
  }
  .card {
    width: 350px;
    .card-img-top {
      width: 100%;
      height: 200px;
      object-fit: cover;
    }
  }
}

.min-height-ver{
  min-height: 380px;
}

@media screen and (max-width: "450px") {
  .VerticalCardForLearnAnytime-main-container {
    .card {
      width: 320px;
      margin: 0.2rem !important;
    }
  }
}

.profile-pic-width {
  width: 50px !important;
  height: 50px !important;
}