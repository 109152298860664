.primary-color-btn {
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
    background-color: #5e51a1;
  }
}

.min-btn-w{
  min-width: max-content;
}

.btn-red {
  background-color: #E4196B !important;
  border: none !important;
}

.btn-red:focus {
  outline: none !important;
}
