.featured-whyus-main-conatiner {
  background-color: #f5f3ff;
  width: 100%;
  padding: 3rem 3.5rem;
}
@media screen and (max-width: 800px) {
  .featured-whyus-inner-conatiner {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
}
@media screen and (max-width: 430px) {
  .featured-whyus-main-conatiner {
    padding: 3rem 1rem !important;
  }
}


@media screen and (min-width: 450px) {
  .featured-whyus-inner-conatiner {
    margin-left: 65px;
    max-width: 85%;
  }  
}

