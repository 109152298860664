.footer-main {
  background-color: #5e51a1;
  padding: 2.5rem 1rem 1rem 1rem;

  .footer-top-main {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 90%;
    margin: 5px;

    h6 {
      color: #fff;
    }
    p {
      color: #d0d5dd;
      font-size: 13px;
      font-weight: 50;
    }
  }
  .separator-line {
    border: 1px solid #d0d5dd;
  }
  .footer-bottom-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .footer-bottom-text {
      color: #eaecf0;
      font-weight: 200;
      font-size: 14px;
    }
    .footer-social-handles-main {
      img {
        margin: 3px;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .footer-top-main {
    width: 100% !important;
  }
}
@media screen and (max-width: 750px) {
  .footer-top-main {
    flex-wrap: wrap;
    .footer-links-logo-container {
      width: 100%;
    }
    .footer-links {
      margin: 10px;
    }
  }
  .footer-bottom-main {
    flex-direction: column;
    .footer-social-handles-main {
      img {
        margin-right: auto;
      }
    }
    .footer-bottom-text {
      margin-right: auto;
    }
  }
}


@media screen and (max-width: 750px) {
  .small-footer-items{
    display: none;
  }
  .margin-small{
    margin-right: 30px !important;
  }
}

@media screen and (min-width: 751px) {
  .big-footer-items{
    display: none;
  }
}