// .dropdown-container {
//   .dropdown-itemMenu {
//     width: 100%;

//     padding: 0.4rem;
//     border-radius: 6px;
//     border-color: rgb(223, 222, 222);
//     &:focus {
//       outline: 0;
//     }
//     &::placeholder {
//       font-size: 10px !important;
//       font-weight: 550;
//       color: #667085;
//     }
//   }
//   .dropdown-items {
//     font-size: 13px !important;
//     font-weight: 550;
//     color: #667085 !important;
//     &::placeholder {
//       color: #667085 !important;
//     }
//   }
// }
.dropdown-main-container {
  position: relative;
  width: 100%;
  .dropdown-menu-icon {
    position: absolute;
    top: 10px;
    left: 6px;
  }
  .dropdown-itemMenu {
    width: 100%;
    padding: 0.4rem;
    border-radius: 6px;
    border-color: rgb(223, 222, 222);
    &:focus {
      outline: 0;
    }
    &::placeholder {
      font-size: 10px !important;
      font-weight: 550;
      color: #000;
    }
  }
  .dropdown-items {
    font-size: 13px !important;
    font-weight: 550;
    color: #000;
  }
  .dropdown_icon_container {
    position: absolute;
    top: 5px;
    right: 17px;
  }
}
