.categorySelection-main-container {
  background-color: #f9fafb;
  height: 100vh;

  .categorySelection-card-container {
    width: 100% !important;
  }

  .categorySelection-btn-container {
    margin-left: auto;
    width: 100px;
    margin-top: 1rem;
  }
}

@media screen and (max-width: 1000px) {
  .categorySelection-btn-container {
    width: 100% !important;
  }
  .categorySelection-card-container {
    display: grid !important;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    width: 100%;
  }
  .categorySelection-inner-container {
    display: flex;
    flex-direction: column;
    .col-4 {
      width: 100%;
    }
  }
}
