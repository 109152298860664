.gift-container{
    display: flex;

}

.main-gift-container{
    margin-left: 20px;
    margin-right: 20px;
}
.gift-div{
    margin-left: 20px;
    margin-right: 20px;
}

@media screen and (max-width: "450px") {
    .gift-container{
        display: flex;
        flex-direction: column;
    }
}


@media screen and (min-width: "850px") {
    .main-bg-div{
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 100px;
        padding-right: 100px;
        background-color: white;
        
    }

    .main-gift-container{
        margin-top: 30px;
        margin-left: 160px;
    }
  
    
}
.text-redeem{
    white-space: nowrap;
    
    text-overflow: ellipsis
}

.voucher-input-container {
    position: relative;
    width: 100%;
    max-width: 400px;
  }
  
  
  .info-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #6c757d; /* Bootstrap's secondary text color */
  }