.inner-container {
  position: relative;
}

@media screen and (min-width: 1000px) {
  .max-w-home{
    max-width: 1400px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }
}
.custom-container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .custom-container {
      max-width: 540px;
  }
}

@media (min-width: 768px) {
  .custom-container {
      max-width: 720px;
  }
}

@media (min-width: 992px) {
  .custom-container {
      max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .custom-container {
      max-width: 1140px;
  }
}

.slider-container {
  width: 100%;
  overflow: hidden; /* Prevents horizontal overflow */
}