.inputWithPhoneCode-container {
  position: relative;
  display: flex;
  align-items: center;
  .input-PhoneCode {
    border-top: 1px solid #dee2e6 !important;
    border-left: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
    border-radius: 5px 0px 0px 5px;
    padding: 6px;
    width: 14%;
    margin: 0;
  }
  .form-control {
    border-left: 0;
    border-radius: 0 5px 5px 0px;
  }
  .error-border {
    border-top: 1px solid #f04438 !important;
    border-right: 1px solid #f04438 !important;
    border-bottom: 1px solid #f04438 !important;
  }
  .err-border {
    border-top: 1px solid #f04438 !important;
    border-left: 1px solid #f04438 !important;
    border-bottom: 1px solid #f04438 !important;
  }
}
